import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { loader } from '../interface/loader';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loader:Subject<loader>= new Subject<loader>();
  loader$=this.loader.asObservable();
  constructor() { }

  setLoader(loader:loader)
  {
    this.loader.next(loader);
  }

  stopLoader()
  {
    this.loader.next({status:false,message:''});
  }
}
