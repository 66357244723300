import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { AccountsNikeService } from "./services/accounts-nike.service";

@Component({
  selector: "app-signin-callback",
  template: `<p>Processing signin callback</p>`,
  styles: [],
})
export class SigninCallbackComponent implements OnInit {
  constructor(private readonly _router: Router, private readonly _accountsService: AccountsNikeService) {}

  async ngOnInit() {
    await this._accountsService.userManager.signinCallback();
    this._router.navigate(['']);
  }
}
