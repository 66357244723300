import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SwooshService {
  private baseUrl: string = environment.swooshBaseUrl;

  constructor(private httpClient: HttpClient) { }

  public swooshRegister(secret: string, access_token: string,id_token:string): Observable<any> {
    let swooshRegisterUrl = `${this.baseUrl}/swoosh/register/v1?secret=${secret}`;
    return this.httpClient.post(swooshRegisterUrl, null,
      { headers: new HttpHeaders().append("Authorization", `Bearer ${access_token}`)
                                  .append("id_token",id_token)});
  }
}
