<header class="dark pb-2">
    <ul ngbNav #nav="ngbNav" [activeId]="1" class="text-white">
      <li class="logo">
        <svg >
          <path d="M56 0.393792L16.9199 18.5121L16.8234 18.5527L16.1255 18.8925C15.9454 18.9779 15.7412 19.072 15.513 19.1748L15.2768 19.2806C14.7883 19.498 14.2183 19.7308 13.567 19.9792C12.9157 20.2277 12.2199 20.4605 11.4798 20.6779C10.7396 20.8953 9.96987 21.0738 9.1705 21.2135C8.37113 21.3533 7.57917 21.4231 6.79461 21.4231C5.66957 21.4231 4.68147 21.264 3.83029 20.9457C2.97911 20.6274 2.27227 20.1888 1.70975 19.6299C1.14723 19.071 0.721651 18.4034 0.43299 17.6271C0.144328 16.8508 0 16.0125 0 15.112C0 14.1649 0.162832 13.1791 0.488501 12.1544C0.81417 11.1297 1.23605 10.1089 1.75416 9.09197C2.27227 8.07504 2.84589 7.09306 3.47502 6.146C4.10415 5.19894 4.72218 4.32952 5.3291 3.53771C5.93603 2.74591 6.50224 2.06279 7.02776 1.48834L7.56743 0.907589C7.83863 0.621338 8.06212 0.395733 8.23791 0.230774C7.48295 1.78333 6.94634 3.1224 6.62807 4.248C6.30981 5.37361 6.15067 6.35559 6.15067 7.19397C6.15067 8.15656 6.31721 8.97553 6.65028 9.65089C6.98335 10.3263 7.42744 10.8735 7.98255 11.2927C8.53767 11.7119 9.1668 12.0185 9.86994 12.2126C10.5731 12.4067 11.3021 12.5037 12.0571 12.5037C12.368 12.5037 12.6936 12.4843 13.0341 12.4455C13.3746 12.4067 13.715 12.3562 14.0555 12.2941C14.396 12.232 14.7216 12.1621 15.0325 12.0845L15.9512 11.8537L31.8907 7.30132L39.8831 5.00583L55.0248 0.674081C55.3473 0.58141 55.6724 0.487981 56 0.393792Z" fill="white"></path>
        </svg>
      </li>
      <li [ngbNavItem]="1" ngbDropdown class="nav-item ml-sm-auto">
        <!-- <span class="small text-capitalize">{{ 'LANGUAGE' | translator }} : </span> -->
        <span>
          <svg class="header-icon-globe" viewBox="0 0 16 16">
            <path d="M8 0C3.57 0 0 3.57 0 8s3.57 8 8 8 8-3.57 8-8-3.57-8-8-8zm-.862 14.4C4.062 13.908 1.6 11.323 1.6 8c0-.492.123-.985.123-1.477l3.815 3.815v.862c0 .862.74 1.6 1.6 1.6v1.6zm5.54-2.092c-.247-.616-.863-1.108-1.478-1.108h-.862V8.738c0-.37-.246-.738-.738-.738H4.8V6.4h1.6c.492 0 .862-.37.862-.862V4.062h1.6c.86 0 1.6-.74 1.6-1.6v-.37C12.8 3.077 14.4 5.292 14.4 8c0 1.723-.615 3.2-1.723 4.308z" fill="white" fill-rule="evenodd">
          </path>
          </svg>
        </span>
        <span class="small lang" ngbDropdownToggle>{{selectedLanguage.name}}</span>
        <div ngbDropdownMenu>
          <button ngbDropdownItem *ngFor="let language of languages" (click)="setLanguage(language, false)">
            <h6 class="small select-lang">{{language.name}}</h6>
          </button>
        </div> 
  
      </li>
     
      </ul>
</header>