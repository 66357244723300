export const environment = {
  production: true,
  local: false,
  deploymentRegion: 'China',
  stsAuthority: 'https://accounts-test.nike.com.cn/',
  clientId: '228e379055687329f85625af5ef7abaf',
  clientRoot: "https://swooshregistration-test.nike.com.cn",
  clientScope: 'openid nike.digital email phone',
  apiRoot: 'https://accounts-test.nike.com.cn/api/',
  swooshBaseUrl: "https://passplayorigin-test.nike.com.cn",//"https://n8-swoosh-ase.n8-passplay01-ase-01.appserviceenvironment.cn",
  termsAndConditions: "https://niketech.service-now.com/nikehr?id=kb_article_view&sys_kb_id=765aa99edbfac918b30c0afed39619e5"
};

